<template>
    <h3>Dashboard</h3>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name     : 'DashBoarD',
    computed : {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
